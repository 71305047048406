import React from "react"
// import styled from "@emotion/styled"
import { css } from "@emotion/react"

// images

import ShopPhoto from "../images/shopPhoto.jpg"


const Footer = () => (
  <footer
	css={css`
	width: 100%;
	padding: 4px 0 8px 0;

	display: grid;
	grid-template-columns: 1fr;

	align-items: center;

	place-items: center;

	grid-template-areas: "shopPhoto" "contactText" "clout";

	grid-gap: 16px;

	background: rgb(195, 22, 28);
	color: white;

	.footerImageContainer {
		grid-area: shopPhoto;
		background: url(${ShopPhoto});
		background-position: center center;
		height: 300px;
		width: 100%;

		@media (min-width: 768px) {
			height: 400px;
			aspect-ratio: 1 / 1;
		}
	}

	.footerTextContainer {
		grid-area: contactText;
		background: black;
		color: white;
		width: 100%;
		height: 100%;

		display: flex;
		flex-direction: column;
		place-items: center;

		padding-top: 2em;
		padding-bottom: 2em;

	}

	.clout {
		grid-area: clout;
		margin: 32px 0 16px 0;
		text-align: center;

		a {
			color: white;
			text-decoration: none;
			:hover {
				text-decoration: underline;
			}
		}
	}

	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		grid-template-areas: "shopPhoto contactText" "clout clout";
		grid-gap: 0;
	}
	`}
  >
  {/* footer image with background */}
  <div className="footerImageContainer">
  </div>

  {/* footer contact text */}
  <div className="footerTextContainer">
  <div id="contact">
	<h2>Outer Limits</h2>
	<p>Unit 100 - 22716 Dewdney Trunk Rd<br />
	Maple Ridge, BC V2X 3K2</p>

	<p>
		Phone: (604) 467-1166
	</p>

	<h3>HOURS</h3>
		<p css={css` margin-bottom: 0;`}>Monday - Friday 9:30am to 5:30pm<br />
		Saturday - Sunday Closed<br />
		<strong>Holidays Closed</strong></p>
	</div>
	</div>

	<div className="clout">
	© {new Date().getFullYear()}, {` `}
	<a href="https://rawmedia.ca/">Web Design, Raw Media</a>
	</div>
  </footer>
)

export default Footer