import React from "react"
// import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Link } from "gatsby"

// images
import LogoImage from "./logo/logo"

// social icons
import Facebook from "../images/social-icons/facebook-icon.png"
// import Instagram from "../images/social-icons/instagram-icon.png"

const Header = () => (
	<header
		css={css`
	max-width: 1142px;
	margin: 32px auto 32px auto;

	display: grid;
	grid-template-columns: 1fr;

	align-items: center;

	place-items: center;

	grid-template-areas: "logoImg" "navLinks" "social_icons";

	grid-gap: 16px;

	@media (min-width: 768px) {
		grid-tempatel-columns: 200px auto 50px;
		grid-template-areas: "logoImg navLinks social_icons";
		place-items: center normal;
		grid-gap: 0;
	}
	`}
	>
		{/* logo wrapper */}
		<div css={css` grid-area: "logoImg"; width: 300px;`}>
			<div css={css``}><LogoImage /></div>
		</div>

		{/* menu wrapper */}
		<div css={css`
		grid-area: "navLinks";
		margin: "0 auto";
		padding: "1.45rem 1.0875rem";
	`}>
			<div css={css`
			display: flex;
			flex-direction: row;
			@media (min-width: 768px) {width: 300px;}

			a {
				padding-left: 16px;
				color: black;
			}
		`}>
				<Link
					to="/"
					css={`
					color: "black";
					text-decoration: "none";
				`}>Home
				</Link>
				<Link to="/contact"
					spy={true}
					smooth={true}
					duration={500}
					offset={-50}>
					Contact
				</Link>
			</div>
		</div>

		{/* social */}
		<div css={css` grid-area: social_icons; img {padding: 0; margin: 0;} `}>
			<a href="https://www.facebook.com/OuterLimitsClothing" target="_blank" rel="noreferrer" title="outerlimits facebook">
				<img src={Facebook} width="50px" height="50px" alt="facebook icon" />
			</a>
			{/* <img src={Instagram} width="50px" height="50px" title="instagram icon" /> */}
		</div>

	</header>
)

export default Header